import initialCrudState from "@/store/templates/crud/state";
import moment from "moment";

const initialModel = () => ({
  draftedAt: moment(),
  finalizedAt: null,
  vatRate: null,
  items: [],
  state: "DRAFT",
  pdfDownloadable: false
});

export default () => ({
  ...initialCrudState(initialModel),
  defaultItem: {
    description: null,
    quantity: null,
    unitPrice: null,
    totalPrice: null
  },
  vat: null,
  tempItem: null,
  user: {},
  employee: {}
});
